import React from "react";
import { Page, Text, StyleSheet, View, Image } from "@react-pdf/renderer";
import {
  ImageProps,
  PullingDesignProps,
} from "../../../../interfaces/interfaces";
import { Header } from "../../components/Header";
import Table from "../../components/Table";
import checked from "../../../../utils/images/checked.png";

const styles = StyleSheet.create({
  page: {
    paddingTop: "80px",
    paddingLeft: "20px",
    paddingRight: "20px",
    fontSize: 12,
    paddingBottom: "80px",
    borderBottom: "0.5px solid black",
  },
  title: {
    fontSize: 12,
    padding: 2,
    marginBottom: 0,
    marginLeft: "30px",
    textAlign: "left",
    marginTop: "20px",
    fontFamily: "Helvetica",
    fontWeight: "bold",
  },
  imageCommon: {
    textAlign: "center",
  },
  table: {
    marginTop: "20px",
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
    fontSize: 10,
    textAlign: "justify",
  },
  tableCol: {
    width: "50%",
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: "justify",
  },
  tableCell: {
    margin: "auto",
    fontSize: 10,
    paddingTop: 2,
    paddingBottom: 2,
    fontFamily: "Helvetica",
    textAlign: "justify",
  },
  header: {
    backgroundColor: "#FFF",
    color: "black",
    fontWeight: "bold",
    fontFamily: "Helvetica",
    fontSize: 10,
    borderTop: "0.5px solid black",
    borderBottom: "0.5px solid black",
    padding: 0,
  },
  row: {
    alignItems: "center",
    backgroundColor: "white",
    borderBottom: "0.25px solid black",
    borderTop: "0.25px solid black",
    padding: 0,
  },
  cell: {
    width: "30%",
    textAlign: "center",
    color: "black",
    fontFamily: "Helvetica",
    fontSize: 10,
    padding: 0,
    height: "100%",
    borderRight: "0.5px solid black",
  },
  cellPicture: {
    width: "50%",
    textAlign: "center",
    justifyContent: "center",
    color: "black",
    fontFamily: "Helvetica",
    fontSize: 10,
    padding: 0,
    height: "100%",
    borderRight: "0.5px solid black",
  },
  cellQty: {
    width: "5%",
    textAlign: "center",
    alignItems: "center",
    alignSelf: "center",
    color: "black",
    padding: 0,
    fontFamily: "Helvetica",
    fontSize: 10,
    alignContent: "center",
    justifyContent: "center",
    height: "100%",
    borderRight: "0.5px solid black",
    borderBottomLeft: "0.5px solid black",
  },
  cellDescription: {
    width: "15%",
    alignItems: "center",
    textAlign: "center",
    color: "black",
    fontFamily: "Helvetica",
    fontSize: 10,
    padding: 0,
    height: "100%",
    justifyContent: "center",
    alignContent: "center",
    alignSelf: "center",
    borderRight: "0.5px solid black",
    borderLeft: "0.5px solid black",
  },
  rowComment: {
    borderBottom: 0,
    borderRight: "0px",
  },
  cellComment: {
    color: "black",
    fontFamily: "Helvetica",
    fontSize: 10,
    padding: 10,
    width: "100%",
    height: "100%",
    textAlign: "justify",
    lineHeight: 1.5,
  },
  cellPictures: {
    width: "50%",
    textAlign: "center",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    alignSelf: "center",
  },
  section: {
    padding: 0,
    flexGrow: 1,
  },
  text: {
    fontSize: 12,
    margin: 2,
    padding: 2,
    flexGrow: 1,
  },
  textComment: {
    fontSize: 10,
    margin: 1,
    padding: 1,
    flexGrow: 1,
  },
  list: {
    flexDirection: "column",
    textAlign: "justify",
    marginLeft: "30px",
    marginRight: "30px",
  },
  checked: {
    marginRight: 10,
    paddingRight: "10px",
    fontWeight: "bold",
  },
  margin: {
    padding: 1,
  },
  marginPictures: {
    marginTop: 10,
    padding: 1,
  },
  padding: {
    padding: 10,
  },
});

export const PullingPage = ({
  pullingInfo,
}: {
  pullingInfo: PullingDesignProps;
}) => {
  const getDate = () => {
    let date1 = new Date(pullingInfo.installationDate);
    let date2 = new Date(pullingInfo.pullingDate);
    let diff = date2.getTime() - date1.getTime();
    return Math.round(diff / (1000 * 60 * 60 * 24));
  };

  const getImageStyles = (image: ImageProps) => {
    const shape = image.size || 1;
    const scale = image.scale || 1;
    switch (shape) {
      case 1:
        return {
          ...styles.imageCommon,
          width: Math.ceil(200 * scale),
          maxWidth: Math.ceil(200 * scale),
          height: Math.ceil(130 * scale),
          maxHeight: Math.ceil(130 * scale),
        };
      case 2:
        return {
          ...styles.imageCommon,
          width: Math.ceil(150 * scale),
          maxWidth: Math.ceil(150 * scale),
          height: Math.ceil(80 * scale),
          maxHeight: Math.ceil(80 * scale),
        };
      case 3:
        return {
          ...styles.imageCommon,
          width: 200 * scale,
          maxWidth: 200 * scale,
          height: 130 * scale,
          maxHeight: 130 * scale,
        };
      case 4:
        return {
          ...styles.imageCommon,
          width: 80 * scale,
          maxWidth: 80 * scale,
          height: 150 * scale,
          maxHeight: 150 * scale,
        };
      case 5:
        return {
          ...styles.imageCommon,
          width: 80 * scale,
          maxWidth: 80 * scale,
          height: 150 * scale,
          maxHeight: 150 * scale,
        };
      default:
        return {
          ...styles.imageCommon,
          width: 130 * scale,
          maxWidth: 130 * scale,
          height: 200 * scale,
          maxHeight: 200 * scale,
        };
    }
  };
  return (
    <Page style={styles.page}>
      <Header showDate={false} />
      <View style={styles.list}>
        <Text style={styles.text}>Pulling Integrity Tool Report</Text>
        <Text style={styles.text}>
          {pullingInfo.well.client && pullingInfo.well.client.name}
        </Text>
        <Text style={styles.text}>
          Well: {pullingInfo.well && pullingInfo.well.name}
        </Text>
        <Text style={styles.text}>
          <Text>Installation Date:</Text>{" "}
          {pullingInfo.installationDate &&
            new Date(pullingInfo.installationDate)
              .toLocaleDateString("en-US")
              .split("T")[0]}
        </Text>
        <Text style={styles.text}>
          Pulling Date:{" "}
          {pullingInfo.pullingDate &&
            new Date(pullingInfo.pullingDate)
              .toLocaleDateString("en-US")
              .split("T")[0]}
        </Text>
        <Text style={styles.text}>
          Runtime:{"  "}
          {getDate()} days
        </Text>
      </View>
      <Text style={styles.title}>Tools Recovered</Text>
      <Table additionalStyles={styles.table}>
        <Table.Header additionalStyles={styles.header}>
          <Table.HeaderCell additionalStyles={styles.cellDescription}>
            DESCRIPTION
          </Table.HeaderCell>
          <Table.HeaderCell additionalStyles={styles.cellQty}>
            QTY
          </Table.HeaderCell>
          <Table.HeaderCell additionalStyles={styles.cellPicture}>
            PHOTOGRAPHIC RECORD
          </Table.HeaderCell>
          <Table.HeaderCell additionalStyles={styles.cell}>
            COMMENTS
          </Table.HeaderCell>
        </Table.Header>
        <Table.Body>
          {pullingInfo.details.map((detail, index) => (
            <React.Fragment key={index}>
              {detail.description.id !== 0 && (
                <Table.Row additionalStyles={styles.row}>
                  <Table.CellView additionalStyles={styles.cellDescription}>
                    <Text style={styles.margin}>
                      {detail.description.name ||
                        detail.description.description}
                    </Text>
                    {detail.toolName && (
                      <Text style={styles.margin}>{detail.toolName}</Text>
                    )}
                  </Table.CellView>
                  <Table.CellView additionalStyles={styles.cellQty}>
                    <Text style={styles.margin}>{detail.quantity || 0}</Text>
                  </Table.CellView>
                  <Table.CellView additionalStyles={styles.cellPicture}>
                    {detail.pictures[0] && detail.pictures[0].image && (
                      <Table.Row additionalStyles={styles.rowComment}>
                        {detail.pictures[0].image !== "" && (
                          <Table.Cell additionalStyles={styles.cellPictures}>
                            <Image
                              src={detail.pictures[0].image}
                              style={getImageStyles(detail.pictures[0])}
                            />
                          </Table.Cell>
                        )}
                        {detail.pictures[1] && detail.pictures[1].image && (
                          <Table.Cell additionalStyles={styles.cellPictures}>
                            <Image
                              src={detail.pictures[1].image}
                              style={getImageStyles(detail.pictures[1])}
                            />
                          </Table.Cell>
                        )}
                      </Table.Row>
                    )}
                    {detail.pictures[2] && detail.pictures[2].image && (
                      <Table.Row additionalStyles={styles.rowComment}>
                        {detail.pictures[2].image !== "" && (
                          <Table.Cell additionalStyles={styles.cellPictures}>
                            <Image
                              src={detail.pictures[2].image}
                              style={getImageStyles(detail.pictures[2])}
                            />
                          </Table.Cell>
                        )}
                        {detail.pictures[3] &&
                          detail.pictures[3].image &&
                          detail.pictures[3].image !== "" && (
                            <Table.Cell additionalStyles={styles.cellPictures}>
                              <Image
                                src={detail.pictures[3].image}
                                style={getImageStyles(detail.pictures[3])}
                              />
                            </Table.Cell>
                          )}
                      </Table.Row>
                    )}
                    {detail.pictures[4] && detail.pictures[4].image && (
                      <Table.Row additionalStyles={styles.rowComment}>
                        {detail.pictures[4].image &&
                          detail.pictures[4].image !== "" && (
                            <Table.Cell additionalStyles={styles.cellPictures}>
                              <Image
                                src={detail.pictures[4].image}
                                style={getImageStyles(detail.pictures[4])}
                              />
                            </Table.Cell>
                          )}
                        {detail.pictures[5] &&
                          detail.pictures[5].image &&
                          detail.pictures[5].image !== "" && (
                            <Table.Cell additionalStyles={styles.cellPictures}>
                              <Image
                                src={detail.pictures[5].image}
                                style={getImageStyles(detail.pictures[5])}
                              />
                            </Table.Cell>
                          )}
                      </Table.Row>
                    )}
                    {detail.pictures[6] && detail.pictures[6].image && (
                      <Table.Row additionalStyles={styles.rowComment}>
                        {detail.pictures[6].image !== "" && (
                          <Table.Cell additionalStyles={styles.cellPictures}>
                            <Image
                              src={detail.pictures[6].image}
                              style={getImageStyles(detail.pictures[6])}
                            />
                          </Table.Cell>
                        )}
                        {detail.pictures[7] &&
                          detail.pictures[7].image !== "" && (
                            <Table.Cell additionalStyles={styles.cellPictures}>
                              <Image
                                src={detail.pictures[7].image}
                                style={getImageStyles(detail.pictures[7])}
                              />
                            </Table.Cell>
                          )}
                      </Table.Row>
                    )}
                    {detail.pictures[8] && detail.pictures[8].image && (
                      <Table.Row additionalStyles={styles.rowComment}>
                        {detail.pictures[8].image && (
                          <Table.Cell additionalStyles={styles.cellPictures}>
                            <Image
                              src={detail.pictures[8].image}
                              style={getImageStyles(detail.pictures[8])}
                            />
                          </Table.Cell>
                        )}
                        {detail.pictures[9] &&
                          detail.pictures[9].image !== "" && (
                            <Table.Cell additionalStyles={styles.cellPictures}>
                              <Image
                                src={detail.pictures[9].image}
                                style={getImageStyles(detail.pictures[9])}
                              />
                            </Table.Cell>
                          )}
                      </Table.Row>
                    )}
                  </Table.CellView>
                  <Table.CellView additionalStyles={styles.cell}>
                    {detail.comment.map((comment, index) => (
                      <React.Fragment key={index}>
                        {comment && (
                          <Table.Row additionalStyles={styles.rowComment}>
                            <Table.Cell additionalStyles={styles.cellComment}>
                              - {comment}
                            </Table.Cell>
                          </Table.Row>
                        )}
                      </React.Fragment>
                    ))}
                  </Table.CellView>
                </Table.Row>
              )}
            </React.Fragment>
          ))}
        </Table.Body>
      </Table>
    </Page>
  );
};
