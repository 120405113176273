import { Document } from "@react-pdf/renderer";
import { PullingDesignProps } from "../../../interfaces/interfaces";
import { PullingPage } from "../pages";
import { RecommendationsPage } from "../pages/pulling/RecommendationsPage";

export const PdfPullingReport = ({
  pullingInfo,
}: {
  pullingInfo: PullingDesignProps;
}) => {
  return (
    <Document>
      <PullingPage pullingInfo={pullingInfo} />
      <RecommendationsPage pullingInfo={pullingInfo} />
    </Document>
  );
};
