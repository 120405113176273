import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import IconButton from "@mui/material/IconButton";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import cloud from "../../utils/images/cloud.jpeg";
import styles from "../components.module.sass";
import { styled } from "@mui/material/styles";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import { ImageProps } from "../../interfaces/interfaces";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export const TitlebarImageList = ({
  itemData,
  index,
  onChange,
  handleDeleteImage,
}: {
  itemData: ImageProps[];
  index: number;
  onChange: (indexLine: number, indexDetail: number, value: ImageProps) => void;
  handleDeleteImage: (indexLine: number, indexDetail: number) => void;
}) => {
  const handleUploadImage = (
    event: React.ChangeEvent<HTMLInputElement>,
    idx: number
  ) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = function () {
        if (reader.result && typeof reader.result === "string") {
          onChange(index, idx, {
            size: 1,
            image: reader.result,
            rotate: 0,
            scale: 1,
          });
        }
      };

      reader.onerror = function () {
        console.log();
      };
    }
  };

  const handleRotateImage = (
    item: ImageProps,
    indexLine: number,
    indexImage: number
  ) => {
    const rotation =
      item.rotate === 0
        ? 90
        : item.rotate === 90
        ? 180
        : item.rotate === 180
        ? 270
        : item.rotate === 270
        ? 0
        : 270;
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const image = new Image();
    if (ctx && item.image) {
      image.onload = () => {
        canvas.width = image.height;
        canvas.height = image.width;
        ctx.rotate(Math.PI / 2); // Rotate by 90 degrees clockwise
        ctx.drawImage(image, 0, -canvas.width);
        const rotatedImage = canvas.toDataURL("image/png");
        onChange(indexLine, indexImage, {
          ...item,
          rotate: rotation,
          image: rotatedImage,
        });
      };
      image.src = item.image;
    }
  };

  return (
    <ImageList sx={{ width: 300, height: 250 }}>
      {itemData.map((item, idx) => (
        <React.Fragment key={idx}>
          <ImageListItem>
            {item ? (
              <img
                srcSet={item.image || cloud}
                src={item.image || cloud}
                alt="img"
                loading="lazy"
                className={styles.imgUpload}
              />
            ) : (
              <img
                srcSet={item}
                src={cloud}
                alt="img"
                loading="lazy"
                className={styles.imgUpload}
              />
            )}

            <ImageListItemBar
              actionIcon={
                <>
                  <Tooltip title="Rotate Image">
                    <IconButton
                      sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                      onClick={() => handleRotateImage(item, index, idx)}
                    >
                      <RotateRightIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Upload Image">
                    <IconButton component="label" size="small">
                      <VisuallyHiddenInput
                        type="file"
                        onChange={(event) => handleUploadImage(event, idx)}
                      />
                      <FileUploadIcon
                        sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Remove Image">
                    <IconButton
                      sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                      onClick={() => handleDeleteImage(index, idx)}
                    >
                      <DeleteOutlineOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                </>
              }
            />
          </ImageListItem>
          <FormControl sx={{ justifySelf: "center", placeSelf: "center" }}>
            <InputLabel id={"selector-shape" + idx}>Shape</InputLabel>
            <Select
              value={item.size}
              label="Shape"
              sx={{ width: 100 }}
              onChange={(event) => {
                const newSize = event.target.value.toString();
                onChange(index, idx, {
                  ...item,
                  size: parseInt(newSize),
                  image: item.image,
                });
              }}
            >
              <MenuItem value={1}>Squared</MenuItem>
              <MenuItem value={2}>Hor. Rectangle</MenuItem>
              <MenuItem value={3}>Hor. Rectangle (2)</MenuItem>
              <MenuItem value={4}>Ver. Rectangle</MenuItem>
              <MenuItem value={5}>Ver. Rectangle (2)</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ justifySelf: "center", placeSelf: "center" }}>
            <InputLabel id={"selector-size" + idx}>Size</InputLabel>
            <Select
              value={item.scale}
              label="Size"
              placeholder="Scale"
              sx={{ width: 100 }}
              onChange={(event) => {
                const newSize = event.target.value.toString();
                console.log(newSize);
                onChange(index, idx, {
                  ...item,
                  scale: parseFloat(newSize),
                });
              }}
            >
              <MenuItem value={0.5}>- 50 %</MenuItem>
              <MenuItem value={0.6}>- 40 %</MenuItem>
              <MenuItem value={0.7}>- 30 %</MenuItem>
              <MenuItem value={0.8}>- 20 %</MenuItem>
              <MenuItem value={0.9}>- 10 %</MenuItem>
              <MenuItem value={1}>0</MenuItem>
              <MenuItem value={1.1}>+ 10 %</MenuItem>
              <MenuItem value={1.2}>+ 20 %</MenuItem>
              <MenuItem value={1.3}>+ 30 %</MenuItem>
              <MenuItem value={1.4}>+ 40 %</MenuItem>
              <MenuItem value={1.5}>+ 50 %</MenuItem>
            </Select>
          </FormControl>
        </React.Fragment>
      ))}
    </ImageList>
  );
};
