import React from "react";
import { Page, Text, StyleSheet, View, Image } from "@react-pdf/renderer";
import { PullingDesignProps } from "../../../../interfaces/interfaces";
import { Header } from "../../components/Header";
import Table from "../../components/Table";
import checked from "../../../../utils/images/checked.png";

const styles = StyleSheet.create({
  page: {
    paddingTop: "80px",
    paddingLeft: "20px",
    paddingRight: "20px",
    fontSize: 12,
    paddingBottom: "80px",
    borderBottom: "0.5px solid black",
  },
  title: {
    fontSize: 12,
    padding: 2,
    marginBottom: 0,
    marginLeft: "30px",
    textAlign: "left",
    marginTop: "20px",
    fontFamily: "Helvetica",
    fontWeight: "bold",
  },

  section: {
    padding: 0,
    flexGrow: 1,
  },
  text: {
    fontSize: 12,
    margin: 2,
    padding: 2,
    flexGrow: 1,
  },
  textComment: {
    fontSize: 10,
    margin: 1,
    padding: 1,
    flexGrow: 1,
  },
  list: {
    flexDirection: "column",
    textAlign: "justify",
    marginLeft: "30px",
    marginRight: "30px",
  },
  checked: {
    marginRight: 10,
    paddingRight: "10px",
    fontWeight: "bold",
  },
  margin: {
    padding: 1,
  },
  marginPictures: {
    marginTop: 10,
    padding: 1,
  },
  padding: {
    padding: 10,
  },
});

export const RecommendationsPage = ({
  pullingInfo,
}: {
  pullingInfo: PullingDesignProps;
}) => {
  return (
    <Page style={styles.page}>
      <Header showDate={false} />
      <Text style={styles.title}>Recommendations and Comments</Text>
      <View style={styles.list}>
        {pullingInfo.recomendations.map((recommendation, index) => (
          <View style={styles.section} key={index}>
            <Text style={styles.text}>
              <Image src={checked} style={styles.checked} />
              {"  "}
              {recommendation}
            </Text>
          </View>
        ))}
      </View>
    </Page>
  );
};
