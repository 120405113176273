import { Button } from "@mui/material";
import {
  AlertComponent,
  NavBar,
  PdfPullingReport,
  PullingAdditionalInfo,
  PullingDetailsTable,
  PullingForm,
  ShowContent,
  TitleComponent,
} from "../../components";
import styles from "../main.module.sass";
import { useContext, useEffect, useState } from "react";
import DataContext from "../../context/DataContext";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { PDFView } from "../../components/pdf";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import DesignerContext from "../../context/DesignerContext";
import { pulling_initial_state } from "../../utils/data";

export const PullingDesignForm = () => {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const pdf = searchParams.get("pdf");
  const [inEdit, setInEdit] = useState(false);
  const [inPdf, setInPdf] = useState(pdf || false);
  const { idPulling } = useParams();
  const { fetchDataClient, fetchDataProducts } = useContext(DataContext);
  const [isReadyToSave, setIsReadyToSave] = useState(false);
  const {
    isSuccess,
    isError,
    isLoading,
    pullingInfo,
    onEditPullingInfo,
    fetchDataPulling,
    handleCreatePulling,
    handleEditPulling,
  } = useContext(DesignerContext);
  useEffect(() => {
    fetchDataClient();
    if (idPulling && idPulling !== "0") {
      fetchDataPulling(parseInt(idPulling));
      setInEdit(true);
    } else {
      onEditPullingInfo(pulling_initial_state);
    }
  }, [idPulling]);

  useEffect(() => {
    if (pullingInfo.well.id !== 0) {
      setIsReadyToSave(true);
    } else {
      setIsReadyToSave(false);
    }
  }, [pullingInfo]);

  useEffect(() => {
    fetchDataProducts();
  }, []);

  const buttons = [
    {
      title: "Cancel",
      action: () => {
        onEditPullingInfo(pulling_initial_state);
        navigate(-1);
      },
      icon: <CancelIcon />,
      disabled: !!inPdf,
    },
    {
      title: "Save",
      action: inEdit
        ? () => idPulling && handleEditPulling(idPulling)
        : handleCreatePulling,
      icon: <SaveIcon />,
      disabled: !isReadyToSave,
    },
    {
      title: !inPdf ? "To Pdf" : "Go Back",
      action: () => {
        setInPdf(!inPdf);
      },
      icon: !inPdf ? <PictureAsPdfIcon /> : <ArrowBackIosIcon />,
    },
  ];

  let content = (
    <>
      <NavBar title="Pulling Report Design" buttons={buttons} />
      <div className={styles.techProposalForm}>
        {inPdf ? (
          <PDFView
            fileName={
              pullingInfo.pullingName +
              "-" +
              pullingInfo.well.client?.name +
              "-" +
              pullingInfo.well.name +
              "-"
            }
            children={<PdfPullingReport pullingInfo={pullingInfo} />}
          />
        ) : (
          <>
            <TitleComponent title="Pulling Report" />
            <PullingForm />
            <TitleComponent title="Details" />
            <PullingDetailsTable />
            <TitleComponent title="Additional Info" />
            <PullingAdditionalInfo />
            <div className={styles.buttonProp}>
              <Button
                variant="contained"
                onClick={() => {
                  onEditPullingInfo(pulling_initial_state);
                  navigate(-1);
                }}
              >
                Cancel
              </Button>
              {inEdit ? (
                <Button
                  variant="contained"
                  onClick={() => idPulling && handleEditPulling(idPulling)}
                  disabled={!isReadyToSave}
                >
                  Save
                </Button>
              ) : (
                <Button
                  variant="contained"
                  onClick={() => handleCreatePulling()}
                  disabled={!isReadyToSave}
                >
                  Save
                </Button>
              )}
            </div>
          </>
        )}

        {isSuccess && <AlertComponent type="success" />}
        {isError && <AlertComponent type="error" />}
      </div>
    </>
  );

  return <ShowContent error={""} isLoading={isLoading} content={content} />;
};
