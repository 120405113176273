import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
  GridDeleteIcon,
} from "@mui/x-data-grid";
import { useContext, useEffect, useState } from "react";
import {
  AddWellForm,
  AlertComponent,
  DialogComponent,
  ModalComponent,
  NavBar,
} from "../../components";
import DataContext from "../../context/DataContext";
import PaginatorContext from "../../context/PaginatorContext";
import { useFetch } from "../../hooks";
import { ProductProps, WellProps } from "../../interfaces/interfaces";
import styles from "../main.module.sass";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { initial_well_state } from "../../utils/data";
import { IconButton, Tooltip, useMediaQuery } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useNavigate } from "react-router-dom";

interface FetchResponse {
  success?: boolean;
  data: ProductProps[];
}

const dataGridStyles = {
  border: "1px solid rgb(90,100,119)",
  borderRadius: "10px",
  padding: "1%",
};

const iconButtonStyles = {
  backgroundColor: "rgb(90,100,119)",
  color: "white",
  marginRight: "5px",
  borderRadius: "10px",
  "&:hover": {
    backgroundColor: "rgb(251,171,53)",
  },
};

export const WellPage = () => {
  const { paginationModel, fetchPaginationModel } =
    useContext(PaginatorContext);
  const navigate = useNavigate();
  const matches = useMediaQuery("(min-width:600px)");
  const { data, isLoading, fetchData, pagination } = useFetch<FetchResponse>(
    "well-paginate",
    paginationModel
  );
  const [isModalOpen, setIsModalOpen] = useState<Boolean>(false);
  const [wellToEdit, setWellToEdit] = useState<WellProps>(initial_well_state);
  const [isCreateWell, setIsCreateWell] = useState<Boolean>(false);
  const { isSuccess, isError, onCreateWell, onEditWell, onDeleteWell } =
    useContext(DataContext);
  const [idToDelete, setIdToDelete] = useState("");
  const [isOpenAlert, setIsOpenAlert] = useState(false);
  const [tableData, setTableData] = useState<ProductProps[] | []>([]);

  useEffect(() => {
    data && data.data && setTableData(data.data);
  }, [data]);

  const columns: GridColDef[] = [
    {
      field: "edit",
      headerName: "Action",
      renderHeader: () => <></>,
      width: 150,
      renderCell: (params: any) => {
        return (
          <>
            <Tooltip title="Detail">
              <IconButton
                onClick={() => {
                  navigate(`/well/${params.row.id}`);
                }}
                sx={iconButtonStyles}
                size="small"
              >
                <OpenInNewIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit">
              <IconButton
                onClick={() => {
                  setIsCreateWell(false);
                  setWellToEdit(params.row);
                  setIsModalOpen(true);
                }}
                sx={iconButtonStyles}
                size="small"
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton
                onClick={() => {
                  setIdToDelete(params.row?.id);
                  setIsOpenAlert(true);
                }}
                sx={iconButtonStyles}
                size="small"
              >
                <GridDeleteIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "name",
      headerName: "Well",
      renderHeader: () => <strong>{"WELL"}</strong>,
      sortable: true,
      width: 220,
    },
    {
      field: "client",
      headerName: "Client",
      renderHeader: () => <strong>{"CLIENT"}</strong>,
      sortable: true,
      width: 220,
      valueGetter: (params: GridValueGetterParams) =>
        params.row?.client.name || "",
    },
    {
      field: "contact",
      headerName: "Contact",
      renderHeader: () => <strong>{"CONTACT"}</strong>,
      width: 220,
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      renderHeader: () => <strong>{"PHONE NUMBER"}</strong>,
      width: 220,
    },
  ];

  const buttons = [
    {
      title: "New",
      action: () => {
        setIsCreateWell(true);
        setIsModalOpen(true);
      },
      icon: <AddBoxIcon />,
    },
  ];

  return (
    <>
      <NavBar title="Wells" buttons={buttons} />
      <div className={styles.center}>
        <div style={{ height: "80vh" }} className={styles.techProposalForm}>
          <DataGrid
            density={matches ? "standard" : "compact"}
            style={dataGridStyles}
            rows={tableData}
            columns={columns}
            className={styles.dataTable}
            loading={isLoading}
            rowCount={+pagination?.totalRecords}
            paginationMode="server"
            paginationModel={paginationModel}
            onPaginationModelChange={fetchPaginationModel}
          />
        </div>
        {isModalOpen && (
          <ModalComponent
            modalContent={
              <AddWellForm
                data={wellToEdit || null}
                onEditWell={
                  !isCreateWell
                    ? (values: WellProps) => {
                        let newWellValues = {
                          id: values.id,
                          name: values.name,
                          idClient: values.client?.id,
                          contact: values.contact,
                          phoneNumber: values.phoneNumber,
                        };
                        onEditWell(newWellValues);
                        fetchData();
                        setIsModalOpen(false);
                      }
                    : undefined
                }
                onAddWell={
                  isCreateWell
                    ? (values: WellProps) => {
                        onCreateWell(values);
                        fetchData();
                        setIsModalOpen(false);
                      }
                    : undefined
                }
                onCancel={() => {
                  setWellToEdit(initial_well_state);
                  setIsModalOpen(false);
                  setIsCreateWell(false);
                }}
              />
            }
          />
        )}
        {isSuccess && <AlertComponent type="success" />}
        {isError && <AlertComponent type="error" />}
      </div>
      {isOpenAlert && (
        <DialogComponent
          type="error"
          modalContent={
            <div>
              Are you sure you want to remove this Well and all the content
              related to it?
            </div>
          }
          onAccept={() => {
            onDeleteWell(idToDelete);
            setIdToDelete("");
            setIsOpenAlert(false);
            fetchData();
          }}
          onCancel={() => {
            setIsOpenAlert(false);
            setIdToDelete("");
          }}
        />
      )}
    </>
  );
};
