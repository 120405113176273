import { IconButton, Tooltip, useMediaQuery } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridToolbar,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import {
  AlertComponent,
  DialogComponent,
  NavBar,
  ShowContent,
} from "../../components";
import styles from "../main.module.sass";
import EditIcon from "@mui/icons-material/Edit";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import PrintIcon from "@mui/icons-material/Print";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import AddBoxIcon from "@mui/icons-material/AddBox";
import DesignerContext from "../../context/DesignerContext";

const dataGridStyles = {
  border: "1px solid rgb(90,100,119)",
  borderRadius: "10px",
  padding: "1%",
};

const iconButtonStyles = {
  backgroundColor: "rgb(90,100,119)",
  color: "white",
  marginRight: "5px",
  borderRadius: "10px",
  "&:hover": {
    backgroundColor: "rgb(251,171,53)",
  },
};

export const PullingDesignList = () => {
  const navigate = useNavigate();
  const {
    fetchPullingList,
    fetchDataPulling,
    pullingList,
    isSuccess,
    isError,
    handleDeletePulling,
  } = useContext(DesignerContext);
  const [idToDelete, setIdToDelete] = useState("");
  const [isOpenAlert, setIsOpenAlert] = useState(false);
  const matches = useMediaQuery("(min-width:600px)");

  useEffect(() => {
    fetchPullingList();
  }, []);

  const columns: GridColDef[] = [
    {
      field: "action",
      headerName: "Action",
      renderHeader: () => <strong>{"ACTION"}</strong>,
      width: 150,
      filterable: false,
      sortable: false,
      renderCell: (params: any) => {
        return (
          <>
            <Tooltip title="Edit">
              <IconButton
                onClick={() => navigate(`/pulling/${params.row?.id}`)}
                sx={iconButtonStyles}
                size="small"
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Duplicate">
              <IconButton
                onClick={() => {
                  fetchDataPulling(params.row?.id);
                  navigate("/pulling/0");
                }}
                size="small"
                sx={iconButtonStyles}
              >
                <FileCopyIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Print">
              <IconButton
                onClick={() => navigate(`/pulling/${params.row?.id}?pdf=true`)}
                sx={iconButtonStyles}
                size="small"
              >
                <PrintIcon fontSize="small" />
              </IconButton>
            </Tooltip>

            <Tooltip title="Delete">
              <IconButton
                onClick={() => {
                  setIdToDelete(params.row?.id);
                  setIsOpenAlert(true);
                }}
                sx={iconButtonStyles}
                size="small"
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "client",
      headerName: "Client",
      renderHeader: () => <strong>{"CLIENT"}</strong>,
      sortable: true,
      width: 220,
      valueGetter: (params: GridValueGetterParams) =>
        params.row?.well.client.name || "",
      renderCell: (params: any) => {
        return (
          <Link
            to={`/pulling/${params.row?.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {params.row?.well.client.name}
          </Link>
        );
      },
    },
    {
      field: "well",
      headerName: "Well",
      renderHeader: () => <strong>{"WELL"}</strong>,
      sortable: true,
      width: 220,
      valueGetter: (params: GridValueGetterParams) =>
        params.row?.well.name || "",
    },
    {
      field: "customName",
      headerName: "Description",
      renderHeader: () => <strong>{"DESCRIPTION"}</strong>,
      width: 300,
      valueGetter: (params: GridValueGetterParams) =>
        params.row?.pullingName + " - " + params.row?.customName,
    },
  ];

  const buttons = [
    {
      title: "New",
      action: () => {
        navigate("/pulling/0");
      },
      icon: <AddBoxIcon />,
    },
  ];
  let content: JSX.Element | JSX.Element[];

  content = (
    <>
      <NavBar title="Pulling Reports" buttons={buttons} />
      <div className={styles.center}>
        <div style={{ height: "80vh" }} className={styles.techProposalForm}>
          <DataGrid
            style={dataGridStyles}
            density={matches ? "standard" : "compact"}
            rows={pullingList}
            columns={columns}
            slots={{ toolbar: GridToolbar }}
            className={styles.dataTable}
            loading={false}
            paginationMode="server"
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          />
        </div>
        {isSuccess && <AlertComponent type="success" />}
        {isError && <AlertComponent type="error" />}
      </div>
      {isOpenAlert && (
        <DialogComponent
          type="error"
          modalContent={<div>Are you sure you want to remove this item?</div>}
          onAccept={() => {
            handleDeletePulling(idToDelete);
            setIdToDelete("");
            setIsOpenAlert(false);
          }}
          onCancel={() => {
            setIsOpenAlert(false);
            setIdToDelete("");
          }}
        />
      )}
    </>
  );

  return <ShowContent error={""} isLoading={false} content={content} />;
};
