import React from "react";
import { View, StyleSheet, Text } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  table: {
    width: "100%",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "rgb(90,100,119)",
    color: "#fff",
    padding: 3,
  },
  headerCell: {
    fontWeight: "heavy",
    fontSize: 10,
    white: "#fff",
    width: "11.11%",
    textAlign: "center",
  },
  headerCellMain: {
    fontWeight: "heavy",
    fontSize: 10,
    white: "#fff",
    width: "30%",
    textAlign: "center",
  },
  body: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#f5f5f5",
  },
  cell: {
    width: "11.11%",
    textAlign: "center",
  },
  cellMain: {
    width: "30%",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    fontSize: 7,
    justifyContent: "space-between",
    alignItems: "center",
    padding: "3px 1px",
    borderBottom: "1px solid black",
    color: "black",
  },
});

const Table = ({
  children,
  additionalStyles,
}: {
  children: any;
  additionalStyles?: any;
}) => <View style={{ ...styles.table, ...additionalStyles }}>{children}</View>;

const TableHeader = ({
  children,
  additionalStyles,
}: {
  children: any;
  additionalStyles?: any;
}) => <View style={{ ...styles.header, ...additionalStyles }}>{children}</View>;

const TableHeaderCell = ({
  children,
  additionalStyles,
}: {
  children: any;
  additionalStyles?: any;
}) => (
  <Text style={{ ...styles.headerCell, ...additionalStyles }}>{children}</Text>
);

const TableHeaderCellMain = ({
  children,
  additionalStyles,
}: {
  children: any;
  additionalStyles?: any;
}) => (
  <Text style={{ ...styles.headerCellMain, ...additionalStyles }}>
    {children}
  </Text>
);

const TableBody = ({
  children,
  additionalStyles,
}: {
  children: any;
  additionalStyles?: any;
}) => <View style={{ ...styles.body, ...additionalStyles }}>{children}</View>;

const TableRow = ({
  children,
  additionalStyles,
}: {
  children: any;
  additionalStyles?: any;
}) => <View style={{ ...styles.row, ...additionalStyles }}>{children}</View>;

const TableCell = ({
  children,
  additionalStyles,
}: {
  children: any;
  additionalStyles?: any;
}) => <Text style={{ ...styles.cell, ...additionalStyles }}>{children}</Text>;

const TableCellView = ({
  children,
  additionalStyles,
}: {
  children: any;
  additionalStyles?: any;
}) => <View style={{ ...styles.cell, ...additionalStyles }}>{children}</View>;

const TableCellMain = ({ children }: { children: any }) => (
  <Text style={styles.cellMain}>{children}</Text>
);

Table.Header = TableHeader;
Table.Body = TableBody;
Table.Row = TableRow;
Table.HeaderCell = TableHeaderCell;
Table.TableHeaderCellMain = TableHeaderCellMain;
Table.Cell = TableCell;
Table.CellMain = TableCellMain;
Table.CellView = TableCellView;

export default Table;
