import { faker } from "@faker-js/faker";
import { PullingDesignDetailsProps } from "../interfaces/interfaces";

export const initial_well_state = {
  client: { id: 0, name: "" },
  name: "",
  contact: "",
  phoneNumber: "",
};

const initial_labels = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const initial_bha_state = {
  casingOd: "",
  weight: "",
  casingId: "",
  driftCasing: "",
  tubing: "",
  bfpd: 0,
  bwpd: 0,
  bopd: 0,
  waterCut: 0,
  gasFlow: 0,
  gor: 0,
  glr: 0,
  tol: null,
  horizontal: true,
  sandLift: false,
  glPacker: false,
  tac: false,
};

export const initial_gas_simulator_state = {
  packerlessSize: "",
  numberGasBodies: 0,
  gasBodyDimensions: "",
  percentageRuntime: 100,
  plungerSize: 0,
  strokeLength: 0,
  pumpSpeed: 0,
  pumpCapacity: 0,
  pumpCapacityByStroke: 0,
  bfpd: 0,
  wCut: 0,
  bopd: 0,
  bwpd: 0,
  gasRate: 0,
  glr: 0,
  wor: 0,
  gor: 0,
  temperature: 180,
  pip: 400,
  neckOD: 0,
  casingId: 4.778,
  tubingOd: 2.875,
  gasSeparatorOD: 2.875,
  gasSeparatorId: 1.941,
  ODdiptube: 1.3,
  waterSP: 1.05,
  gasSP: 0.8,
  oilApi: 38,
  oilSP: 0.83,
  interfacialTension: 0.04,
  gravitationalForce: 32,
  gasSeparatorLength: 0,
};

export const initial_gas_simulator_results = {
  correlationTempAndOilApi: 0,
  deviationFactor: 0,
  pseudoCriticalTemperature: 0,
  pseudoCriticalPressure: 0,
  pseudoReducedTemperature: 0,
  pseudoReducedPressure: 0,
  solutionGas: 0,
  correlationSolutionGas: 0,
  oilVolumeFactor: 0,
  waterVolumeFactor: 0,
  gasVolumeFactor: 0,
  gasDensity: 0,
  liquidDensity: 0,
  freeGasEnteringPump: 0,
  gasBubbleTerminalVelocity: 0,
  crossSectionalArea: 0,
  inSituSuperficialLiquidVelocity: 0,
  naturalSeparationEfficiency: 0,
  freeGasEnteringPumbWithSeparator: 0,
  quiteZoneVolume: 0,
  effectiveStrokes: 0,
  retentionTime: 0,
};

export const initial_sand_simulator_state = {
  bfpd: 0,
  percentageRuntime: 0,
  selectedTubingScreen: "2-3/8\" x 8'",
  selectedSuperPerf: '2-3/8"',
  selectedPumbGuard: '1" x 9"',
  slot: "0.006",
  slotPg: "0.006",
  slotSp: "0.075",
  wellClasification: "AVERAGE",
  openAreaOfScreen: 0,
  numberOfTubingScreen: 0,
  numberOfPumpGuard: 0,
};

export const initial_sand_simulator_results = {
  sizeOfSand: 50,
  totalOpenAreaOfScreen: 0,
  minutePerDay: 0,
  productionPerMinuteOfRun: 0,
  productionCubicInches: 0,
  productionInchByOpening: 0,
  maxByTs: 0,
};

export const initial_press_simulator_state = {
  bfpd: 0,
  wCut: 0,
  DipTubId: 0,
  dipTubeLength: 0,
  bopd: 0,
  bwpd: 0,
  gasRate: 0,
  gor: 0,
  api: 0,
  sp: 0,
  spo: 0,
  spw: 0,
  viscocity: 0,
  numberOfTubingScreen: 0,
  diameter: 0,
  slotSize: "75",
  openArea: 0,
  screenLength: 0,
  pip: 0,
  deltaP: 0,
  refDepth: 0,
  resPressure: 0,
  temperature: 160,
  gasViscosity: 0,
  ap: 0.0055,
  surfTension: 0.058,
  avgPressure: 0,
  rs: 0,
  bo: 0,
  prd: 0,
  trd: 0,
  z: 0,
};

export const initial_press_simulator_results = {
  waterRate: 0,
  flowRegime: 0,
  oilRate: 0,
  relativeRoughness: 0,
  velocity: 0,
  reynolds: 0,
  ed: 0,
  frictionFactor: 0,
  f: 0,
  deltaP: 0,
  screenAperture: 0,
  freeArea: 0,
  fluidDensity: 0,
  fluidVelocity: 0,
  dischargeCoefficient: 0,
  lossPressCoeff: 0,
  lossPressure: 0,
};

export const initial_solution_state = {
  sandSolution: false,
  sandSimulator: {
    data: initial_sand_simulator_state,
    results: initial_sand_simulator_results,
  },
  gasSolution: false,
  gasSimulator: {
    data: initial_gas_simulator_state,
    results: initial_gas_simulator_results,
  },
  pressureSolution: false,
  pressureSimulator: {
    data: initial_press_simulator_state,
    results: initial_press_simulator_results,
  },
};

export const initial_simulator_state = {
  sand: {
    tubingScreen: true,
    pumpGuard: false,
    superPerf: false,
  },
  gas: {
    gforce: true,
    packerType: false,
    poorBoy: false,
  },
  pressure: {
    dipTube: true,
    tubingScreenDP: false,
  },
};

export const initial_prod_chart = {
  labels: initial_labels,
  dataset1: initial_labels.map(() => faker.datatype.number({ min: 0, max: 0 })),
  dataset2: initial_labels.map(() => faker.datatype.number({ min: 0, max: 0 })),
  dataset3: initial_labels.map(() => faker.datatype.number({ min: 0, max: 0 })),
};

export const basic_initial_state = {
  customName: "",
  client: {
    id: 0,
    name: "",
    wells: [],
  },
  well: {
    id: 0,
    name: "",
    contact: "",
    phoneNumber: "",
  },
  sla: { id: 0, name: "", reqField: null, image: "" },
  mdDepth: 0,
  totalDepth: 0,
  solution: initial_solution_state,
  bhaInfo: initial_bha_state,
  prodChartData: initial_prod_chart,
  prodImage: "",
};

export const new_tally_row = {
  id: 0,
  partNumber: "",
  name: "",
  description: "",
  supplier: "OSI",
  topThreadConnection: "",
  bottomThreadConnection: "",
  status: { id: 0, name: "NEW" },
  maxOD: "",
  bodyOD: "",
  length: 0,
  quantity: 1,
  weight: 0,
  top: 0,
  bottom: 0,
  totalWeight: 0,
  size: 1,
  osi: true,
  imagePath: null,
};

export const new_tally_custom_row = {
  id: 0,
  partNumber: "",
  name: "",
  description: "",
  supplier: "",
  topThreadConnection: "",
  bottomThreadConnection: "",
  status: { id: 0, name: "NEW" },
  maxOD: "",
  bodyOD: "",
  length: 0,
  quantity: 1,
  weight: 0,
  top: 0,
  bottom: 0,
  totalWeight: 0,
  size: 1,
  osi: false,
  imagePath: null,
};

export const initial_tool_state = {
  id: 0,
  partNumber: "",
  name: "",
  description: "",
  supplier: "",
  topThreadConnection: "",
  bottomThreadConnection: "",
  status: { id: 0, name: "" },
  maxOD: "",
  bodyOD: "",
  length: 0,
  quantity: 1,
  weight: 0,
  osi: false,
  tailJoint: false,
  espAssembly: false,
  imagePath: null,
  size: 0,
  isCompound: false,
  parts: [],
};

export const tally_initial_state = [new_tally_row];

export const new_wbd_item = {
  position: 0,
  tool: new_tally_row,
  tol: false,
  perforations: false,
  last: false,
  tempImage: null,
};

export const new_wbd_custom_item = {
  position: 0,
  tool: new_tally_custom_row,
  tol: false,
  perforations: false,
  last: false,
  tempImage: null,
};

export const initial_wbd_state = [new_wbd_item];

export const initial_sales_state = {
  id: 0,
  orderDate: new Date().toISOString().slice(0, 10),
  quoteNumber: "",
  client: "",
  city: "MIDLAND",
  stateZip: "TX",
  country: "USA",
  phoneNumber: "",
  email: "",
  contact: "",
  po: "",
  needBy: "",
  wellName: "",
  directions: "",
  deliveryContact: "",
  salesmanContact: "",
  specialNotes: "",
  productList: [new_tally_row],
  wellId: 0,
};

export const pump_guard_open_area = {
  '1" x 9"': {
    0.006: 3.3,
    0.012: 6.1,
    0.018: 8.5,
    0.025: 10.8,
    0.035: 13.5,
    0.05: 16.7,
    0.075: 20.4,
    0.125: 24.8,
  },
  "1\" x 2'": {
    0.006: 8.9,
    0.012: 16.3,
    0.018: 22.6,
    0.025: 28.8,
    0.035: 36.1,
    0.05: 44.5,
    0.075: 54.4,
    0.125: 66.2,
  },
  "1\" x 3'": {
    0.006: 13.4,
    0.012: 24.5,
    0.018: 33.9,
    0.025: 43.2,
    0.035: 54.1,
    0.05: 66.8,
    0.075: 81.6,
    0.125: 99.3,
  },
  "1\" x 6'": {
    0.006: 26.7,
    0.012: 49.0,
    0.018: 67.8,
    0.025: 86.4,
    0.035: 108.3,
    0.05: 133.6,
    0.075: 163.3,
    0.125: 198.6,
  },
  "1\" x 8'": {
    0.006: 35.6,
    0.012: 65.3,
    0.018: 90.4,
    0.025: 115.2,
    0.035: 144.4,
    0.05: 178.1,
    0.075: 217.7,
    0.125: 264.8,
  },
  "1\" x 10'": {
    0.006: 44.5,
    0.012: 81.6,
    0.018: 113.0,
    0.025: 144.1,
    0.035: 180.5,
    0.05: 222.7,
    0.075: 272.1,
    0.125: 330.9,
  },
  '1-1/4" x 9"': {
    0.006: 4.1,
    0.012: 7.5,
    0.018: 10.4,
    0.025: 13.3,
    0.035: 16.7,
    0.05: 20.6,
    0.075: 25.1,
    0.125: 30.6,
  },
  "1-1/4\" x 2'": {
    0.006: 11.0,
    0.012: 20.1,
    0.018: 27.8,
    0.025: 35.5,
    0.035: 44.4,
    0.05: 54.8,
    0.075: 67.0,
    0.125: 81.5,
  },
  "1-1/4\" x 3'": {
    0.006: 16.4,
    0.012: 30.1,
    0.018: 41.7,
    0.025: 53.2,
    0.035: 66.6,
    0.05: 82.2,
    0.075: 100.5,
    0.125: 122.2,
  },
  "1-1/4\" x 6'": {
    0.006: 32.9,
    0.012: 60.3,
    0.018: 83.5,
    0.025: 106.4,
    0.035: 133.3,
    0.05: 164.4,
    0.075: 201.0,
    0.125: 244.4,
  },
  "1-1/4\" x 8'": {
    0.006: 43.8,
    0.012: 80.4,
    0.018: 111.3,
    0.025: 141.9,
    0.035: 177.7,
    0.05: 219.2,
    0.075: 267.9,
    0.125: 325.8,
  },
  "1-1/4\" x 10'": {
    0.006: 44.5,
    0.012: 81.6,
    0.018: 113.0,
    0.025: 144.1,
    0.035: 180.5,
    0.05: 222.7,
    0.075: 272.1,
    0.125: 330.9,
  },
  '1-1/2" x 9"': {
    0.006: 4.9,
    0.012: 8.9,
    0.018: 12.4,
    0.025: 15.8,
    0.035: 19.8,
    0.05: 24.4,
    0.075: 29.8,
    0.125: 36.3,
  },
  "1-1/2\" x 2'": {
    0.006: 13.0,
    0.012: 23.9,
    0.018: 33.0,
    0.025: 42.1,
    0.035: 52.8,
    0.05: 65.1,
    0.075: 79.6,
    0.125: 96.8,
  },
  "1-1/2\" x 3'": {
    0.006: 19.5,
    0.012: 35.8,
    0.018: 49.6,
    0.025: 63.2,
    0.035: 79.1,
    0.05: 97.6,
    0.075: 119.3,
    0.125: 145.1,
  },
  "1-1/2\" x 6'": {
    0.006: 39.1,
    0.012: 71.6,
    0.018: 99.1,
    0.025: 126.3,
    0.035: 158.3,
    0.05: 195.3,
    0.075: 238.6,
    0.125: 290.2,
  },
  "1-1/2\" x 8'": {
    0.006: 52.1,
    0.012: 95.5,
    0.018: 132.2,
    0.025: 168.5,
    0.035: 211.0,
    0.05: 260.3,
    0.075: 318.2,
    0.125: 387.0,
  },
  "1-1/2\" x 10'": {
    0.006: 65.1,
    0.012: 119.3,
    0.018: 165.2,
    0.025: 210.6,
    0.035: 263.8,
    0.05: 325.4,
    0.075: 397.7,
    0.125: 483.7,
  },
};

export const screen_apreture_table = {
  75: 0.0122,
  50: 0.00507,
  20: 0.00284,
  15: 0.00151,
  12: 0.00106,
  10: 0.00088,
};

export const gForcePackerlessDimensions = {
  "350": {
    neckOD: 1.89,
    neckId: 1.09,
    bodyOd: 3.5,
    bodyId: 2.9,
    dipTubeOd: 1.3,
    dipTubeId: 1,
  },
  "400": {
    neckOD: 1.89,
    neckId: 1.09,
    bodyOd: 4,
    bodyId: 3.4,
    dipTubeOd: 1.3,
    dipTubeId: 1,
  },
  "450": {
    neckOD: 1.89,
    neckId: 1.09,
    bodyOd: 4.5,
    bodyId: 3.91,
    dipTubeOd: 1.3,
    dipTubeId: 1,
  },
  "500": {
    neckOD: 1.89,
    neckId: 1.09,
    bodyOd: 5,
    bodyId: 4.276,
    dipTubeOd: 1.3,
    dipTubeId: 1,
  },
  "550": {
    neckOD: 1.89,
    neckId: 1.09,
    bodyOd: 5.5,
    bodyId: 4.91,
    dipTubeOd: 1.3,
    dipTubeId: 1,
  },
};

export const casingOdOptions = [
  '4-1/2" - 9.50',
  '4-1/2" - 10.50',
  '4-1/2" - 11.60',
  '4-1/2" - 12.60',
  '4-1/2" - 13.50',
  '4-1/2" - 15.10',
  '4-1/2" - 16.60',
  '4-1/2" - 16.90',
  '4-1/2" - 17.70',
  '4-1/2" - 18.80',
  '4-1/2" - 21.60',
  '4-1/2" - 24.60',
  '4-1/2" - 26.50',
  '5" - 11.50',
  '5" - 13.00',
  '5" - 15.00',
  '5" - 18.00',
  '5" - 20.30',
  '5" - 20.80',
  '5" - 21.40',
  '5" - 23.20',
  '5" - 24.20',
  '5" - 26.70',
  '5" - 32.00',
  '5-1/2" - 13.00',
  '5-1/2" - 14.00',
  '5-1/2" - 15.50',
  '5-1/2" - 17.00',
  '5-1/2" - 20.00',
  '5-1/2" - 23.00',
  '5-1/2" - 26.00',
  '5-1/2" - 28.40',
  '5-1/2" - 29.70',
  '5-1/2" - 32.30',
  '5-1/2" - 36.40',
  '5-1/2" - 39.30',
  '6-5/8" - 17.00',
  '6-5/8" - 20.00',
  '6-5/8" - 24.00',
  '6-5/8" - 28.00',
  '6-5/8" - 32.00',
  '6-5/8" - 35.00',
  '6-5/8" - 43.20',
  '6-5/8" - 69.63',
  '7" - 17.00',
  '7" - 20.00',
  '7" - 23.00',
  '7" - 26.00',
  '7" - 29.00',
  '7" - 32.00',
  '7" - 35.00',
  '7" - 38.00',
  '7" - 41.00',
  '7" - 42.70',
  '7" - 44.00',
  '7" - 45.40',
  '7" - 49.50',
  '7" - 56.10',
  '7" - 58.00',
  '7" - 66.50',
  '7-5/8" - 20.00',
  '7-5/8" - 24.00',
  '7-5/8" - 26.40',
  '7-5/8" - 29.70',
  '7-5/8" - 33.70',
  '7-5/8" - 39.00',
  '7-5/8" - 42.80',
  '7-5/8" - 45.30',
  '7-5/8" - 47.10',
  '7-5/8" - 51.20',
  '7-5/8" - 52.80',
  '7-5/8" - 55.75',
  '7-3/4" - 46.10',
  '8-5/8" - 24.00',
  '8-5/8" - 28.00',
  '8-5/8" - 32.00',
  '8-5/8" - 36.00',
  '8-5/8" - 40.00',
  '8-5/8" - 44.00',
  '8-5/8" - 49.00',
  '8-5/8" - 52.00',
  '8-3/4" - 49.70',
  '9-5/8" - 29.30',
  '9-5/8" - 32.30',
  '9-5/8" - 36.00',
  '9-5/8" - 38.00',
  '9-5/8" - 40.00',
  '9-5/8" - 43.50',
  '9-5/8" - 47.00',
  '9-5/8" - 53.50',
  '9-5/8" - 58.40',
  '9-5/8" - 59.40',
  '9-5/8" - 61.10',
  '9-5/8" - 64.90',
  '9-5/8" - 70.30',
  '9-5/8" - 71.80',
  '9-3/4" - 59.20',
  '9-7/8" - 62.80',
  '10-3/4" - 32.75',
  '10-3/4" - 35.75',
  '10-3/4" - 40.50',
  '10-3/4" - 45.50',
  '10-3/4" - 51.00',
  '10-3/4" - 55.50',
  '10-3/4" - 60.70',
  '10-3/4" - 65.70',
  '10-3/4" - 71.10',
  '10-3/4" - 73.20',
  '10-3/4" - 76.00',
  '10-3/4" - 79.20',
  '10-3/4" - 81.00',
  '11-3/4" - 38.00',
  '11-3/4" - 42.00',
  '11-3/4" - 47.00',
  '11-3/4" - 54.00',
  '11-3/4" - 60.00',
  '11-3/4" - 65.00',
  '11-3/4" - 66.70',
  '11-3/4" - 71.00',
  '11-3/4" - 73.60',
  '11-3/4" - 75.00',
  '11-3/4" - 76.00',
  '11-3/4" - 79.00',
  '11-3/4" - 80.50',
  '11-3/4" - 83.00',
  '11-3/4" - 87.20',
  '11-3/4" - 95.00',
  '11-7/8" - 71.80',
  '13-3/8" - 48.00',
  '13-3/8" - 54.50',
  '13-3/8" - 61.00',
  '13-3/8" - 68.00',
  '13-3/8" - 72.00',
  '13-3/8" - 77.00',
  '13-3/8" - 80.70',
  '13-3/8" - 83.00',
  '13-3/8" - 85.00',
  '13-3/8" - 86.00',
  '13-3/8" - 91.00',
  '13-3/8" - 92.00',
  '13-3/8" - 96.00',
  '13-3/8" - 98.00',
  '13-3/8" - 100.30',
  '13-3/8" - 102.00',
  '13-1/2" - 81.40',
  '13-5/8" - 88.20',
];

export const api_casing_table_drift = {
  '4-1/2" - 9.50': { id: "4.090", drift: "3.965" },
  '4-1/2" - 10.50': { id: "4.052", drift: "3.927" },
  '4-1/2" - 11.60': { id: "4.000", drift: "3.875" },
  '4-1/2" - 12.60': { id: "3.958", drift: "3.833" },
  '4-1/2" - 13.50': { id: "3.920", drift: "3.795" },
  '4-1/2" - 15.10': { id: "3.826", drift: "3.701" },
  '4-1/2" - 16.60': { id: "3.754", drift: "3.629" },
  '4-1/2" - 16.90': { id: "3.740", drift: "3.615" },
  '4-1/2" - 17.70': { id: "3.696", drift: "3.571" },
  '4-1/2" - 18.80': { id: "3.640", drift: "3.515" },
  '4-1/2" - 21.60': { id: "3.500", drift: "3.375" },
  '4-1/2" - 24.60': { id: "3.380", drift: "3.255" },
  '4-1/2" - 26.50': { id: "3.240", drift: "3.115" },
  '5" - 11.50': { id: "4.560", drift: "4.435" },
  '5" - 13.00': { id: "4.494", drift: "4.369" },
  '5" - 15.00': { id: "4.408", drift: "4.283" },
  '5" - 18.00': { id: "4.276", drift: "4.151" },
  '5" - 20.30': { id: "4.184", drift: "4.059" },
  '5" - 20.80': { id: "4.156", drift: "4.031" },
  '5" - 21.40': { id: "4.126", drift: "4.001" },
  '5" - 23.20': { id: "4.044", drift: "3.919" },
  '5" - 24.20': { id: "4.000", drift: "3.875" },
  '5" - 26.70': { id: "3.876", drift: "3.751" },
  '5" - 32.00': { id: "3.620", drift: "3.495" },
  '5-1/2" - 13.00': { id: "5.044", drift: "4.919" },
  '5-1/2" - 14.00': { id: "5.012", drift: "4.887" },
  '5-1/2" - 15.50': { id: "4.950", drift: "4.825" },
  '5-1/2" - 17.00': { id: "4.892", drift: "4.767" },
  '5-1/2" - 20.00': { id: "4.778", drift: "4.653" },
  '5-1/2" - 23.00': { id: "4.670", drift: "4.545" },
  '5-1/2" - 26.00': { id: "4.548", drift: "4.423" },
  '5-1/2" - 28.40': { id: "4.440", drift: "4.315" },
  '5-1/2" - 29.70': { id: "4.376", drift: "4.251" },
  '5-1/2" - 32.30': { id: "4.276", drift: "4.151" },
  '5-1/2" - 36.40': { id: "4.090", drift: "3.965" },
  '5-1/2" - 39.30': { id: "4.044 ", drift: "3.919" },
  '6" - 15.00': { id: "5.542", drift: "5.399" },
  '6" - 18.00': { id: "5.424", drift: "5.299" },
  '6" - 20.00': { id: "5.352", drift: "5.227" },
  '6" - 23.00': { id: "5.240", drift: "5.115" },
  '6" - 26.00': { id: "5.132", drift: "5.007" },
  '6-5/8" - 17.00': { id: "6.135", drift: "6.010" },
  '6-5/8" - 20.00': { id: "6.049", drift: "5.924" },
  '6-5/8" - 24.00': { id: "5.921", drift: "5.796" },
  '6-5/8" - 28.00': { id: "5.791", drift: "5.666" },
  '6-5/8" - 32.00': { id: "5.675", drift: "5.550" },
  '6-5/8" - 35.00': { id: "5.575", drift: "5.450" },
  '6-5/8" - 43.20': { id: "5.375", drift: "5.250" },
  '6-5/8" - 69.63': { id: "4.375", drift: "4.250" },
  '7" - 17.00': { id: "6.538", drift: "6.413" },
  '7" - 20.00': { id: "6.456", drift: "6.331" },
  '7" - 23.00': { id: "6.366", drift: "6.241" },
  '7" - 26.00': { id: "6.276", drift: "6.151" },
  '7" - 29.00': { id: "6.184", drift: "6.059" },
  '7" - 32.00': { id: "6.094", drift: "5.969" },
  '7" - 35.00': { id: "6.004", drift: "5.879" },
  '7" - 38.00': { id: "5.920", drift: "5.795" },
  '7" - 41.00': { id: "5.820", drift: "5.695" },
  '7" - 42.70': { id: "5.750", drift: "5.625" },
  '7" - 44.00': { id: "5.720", drift: "5.595" },
  '7" - 45.40': { id: "5.660", drift: "5.535" },
  '7" - 49.50': { id: "5.540", drift: "5.415" },
  '7" - 56.10': { id: "5.376", drift: "5.251" },
  '7" - 58.00': { id: "5.240", drift: "5.115" },
  '7" - 66.50': { id: "5.040", drift: "4.915" },
  '7-5/8" - 20.00': { id: "7.125", drift: "7.000" },
  '7-5/8" - 24.00': { id: "7.025", drift: "6.900" },
  '7-5/8" - 26.40': { id: "6.969", drift: "6.844" },
  '7-5/8" - 29.70': { id: "6.875", drift: "6.750" },
  '7-5/8" - 33.70': { id: "6.765", drift: "6.640" },
  '7-5/8" - 39.00': { id: "6.625", drift: "6.500" },
  '7-5/8" - 42.80': { id: "6.501", drift: "6.376" },
  '7-5/8" - 45.30': { id: "6.435", drift: "6.310" },
  '7-5/8" - 47.10': { id: "6.375", drift: "6.250" },
  '7-5/8" - 51.20': { id: "6.249", drift: "6.125" },
  '7-5/8" - 52.80': { id: "6.201", drift: "6.000" },
  '7-5/8" - 55.75': { id: "6.201", drift: "6.176" },
  '7-3/4" - 46.10': { id: "6.560", drift: "6.500" },
  '8-5/8" - 24.00': { id: "8.097", drift: "7.972" },
  '8-5/8" - 28.00': { id: "8.017", drift: "7.892" },
  '8-5/8" - 32.00': { id: "7.921", drift: "7.796" },
  '8-5/8" - 36.00': { id: "7.825", drift: "7.700" },
  '8-5/8" - 40.00': { id: "7.725", drift: "7.600" },
  '8-5/8" - 44.00': { id: "7.625", drift: "7.500" },
  '8-5/8" - 49.00': { id: "7.511", drift: "7.386" },
  '8-5/8" - 52.00': { id: "7.435", drift: "7.310" },
  '8-3/4" - 49.70': { id: "7.636", drift: "7.500" },
  '9-5/8" - 29.30': { id: "9.063", drift: "8.907" },
  '9-5/8" - 32.30': { id: "9.001", drift: "8.845" },
  '9-5/8" - 36.00': { id: "8.921", drift: "8.765" },
  '9-5/8" - 38.00': { id: "8.885", drift: "8.760" },
  '9-5/8" - 40.00': { id: "8.835", drift: "8.679" },
  '9-5/8" - 43.50': { id: "8.755", drift: "8.599" },
  '9-5/8" - 47.00': { id: "8.681", drift: "8.525" },
  '9-5/8" - 53.50': { id: "8.535", drift: "8.379" },
  '9-5/8" - 58.40': { id: "8.435", drift: "8.279" },
  '9-5/8" - 59.40': { id: "8.407", drift: "8.251" },
  '9-5/8" - 61.10': { id: "8.375", drift: "8.219" },
  '9-5/8" - 64.90': { id: "8.281", drift: "8.125" },
  '9-5/8" - 70.30': { id: "8.157", drift: "8.001" },
  '9-5/8" - 71.80': { id: "8.125", drift: "7.969" },
  '9-3/4" - 59.20': { id: "8.560", drift: "8.500" },
  '9-7/8" - 62.80': { id: "8.625", drift: "8.500" },
  '10-3/4" - 32.75': { id: "10.192", drift: "10.036" },
  '10-3/4" - 35.75': { id: "10.136", drift: "10.011" },
  '10-3/4" - 40.50': { id: "10.050", drift: "9.894" },
  '10-3/4" - 45.50': { id: "9.950", drift: "9.794" },
  '10-3/4" - 51.00': { id: "9.850", drift: "9.694" },
  '10-3/4" - 55.50': { id: "9.760", drift: "9.604" },
  '10-3/4" - 60.70': { id: "9.660", drift: "9.504" },
  '10-3/4" - 65.70': { id: "9.560", drift: "9.404" },
  '10-3/4" - 71.10': { id: "9.450", drift: "9.294" },
  '10-3/4" - 73.20': { id: "9.406", drift: "9.250" },
  '10-3/4" - 76.00': { id: "9.350", drift: "9.194" },
  '10-3/4" - 79.20': { id: "9.282", drift: "9.126" },
  '10-3/4" - 81.00': { id: "9.250", drift: "9.094" },
  '11-3/4" - 38.00': { id: "11.150", drift: "10.994" },
  '11-3/4" - 42.00': { id: "11.084", drift: "10.928" },
  '11-3/4" - 47.00': { id: "11.000", drift: "10.844" },
  '11-3/4" - 54.00': { id: "10.880", drift: "10.724" },
  '11-3/4" - 60.00': { id: "10.772", drift: "10.616" },
  '11-3/4" - 65.00': { id: "10.682", drift: "10.526" },
  '11-3/4" - 66.70': { id: "10.656", drift: "10.500" },
  '11-3/4" - 71.00': { id: "10.586", drift: "10.430" },
  '11-3/4" - 73.60': { id: "10.532", drift: "10.376" },
  '11-3/4" - 75.00': { id: "10.514", drift: "10.358" },
  '11-3/4" - 76.00': { id: "10.500", drift: "10.344" },
  '11-3/4" - 79.00': { id: "10.438", drift: "10.282" },
  '11-3/4" - 80.50': { id: "10.406", drift: "10.25" },
  '11-3/4" - 83.00': { id: "10.368", drift: "10.212" },
  '11-3/4" - 87.20': { id: "10.282", drift: "10.126" },
  '11-3/4" - 95.00': { id: "10.124", drift: "9.968" },
  '11-7/8" - 71.80': { id: "10.711", drift: "10.625" },
  '13-3/8" - 48.00': { id: "12.715", drift: "12.559" },
  '13-3/8" - 54.50': { id: "12.615", drift: "12.459" },
  '13-3/8" - 61.00': { id: "12.515", drift: "12.359" },
  '13-3/8" - 68.00': { id: "12.415", drift: "12.259" },
  '13-3/8" - 72.00': { id: "12.347", drift: "12.191" },
  '13-3/8" - 77.00': { id: "12.275", drift: "12.119" },
  '13-3/8" - 80.70': { id: "12.215", drift: "12.059" },
  '13-3/8" - 83.00': { id: "12.175", drift: "12.019" },
  '13-3/8" - 85.00': { id: "12.159", drift: "12.003" },
  '13-3/8" - 86.00': { id: "12.125", drift: "11.969" },
  '13-3/8" - 91.00': { id: "12.055", drift: "11.899" },
  '13-3/8" - 92.00': { id: "12.031", drift: "11.875" },
  '13-3/8" - 96.00': { id: "11.975", drift: "11.819" },
  '13-3/8" - 98.00': { id: "11.937", drift: "11.781" },
  '13-3/8" - 100.30': { id: "11.907", drift: "11.751" },
  '13-3/8" - 102.00': { id: "11.889", drift: "11.733" },
  '13-1/2" - 81.40': { id: "12.340", drift: "12.250" },
  '13-5/8" - 88.20': { id: "12.375", drift: "12.250" },
};

export const max_velocity_ts = [
  {
    slot: "0.006",
    oldVelocity: 0.15475,
    factor: 1.8,
    AVERAGE: 1,
    BAD: 2.233,
    SEVERE: 5.40386,
  },
  {
    slot: "0.008",
    oldVelocity: 0.16844,
    factor: 1.8,
    AVERAGE: 1,
    BAD: 2.1428,
    SEVERE: 4.99979524,
  },
  {
    slot: "0.01",
    oldVelocity: 0.25266,
    factor: 1.8,
    AVERAGE: 1,
    BAD: 2.083,
    SEVERE: 4.7611131,
  },
  {
    slot: "0.012",
    oldVelocity: 0.33688,
    factor: 1.8,
    AVERAGE: 1,
    BAD: 2,
    SEVERE: 4.2856,
  },
  {
    slot: "0.015",
    oldVelocity: 0.35933,
    factor: 1.8,
    AVERAGE: 1,
    BAD: 1.7777,
    SEVERE: 3.5554,
  },
  {
    slot: "0.018",
    oldVelocity: 0.39812,
    factor: 1.9,
    AVERAGE: 1,
    BAD: 1.7023,
    SEVERE: 3.1550428,
  },
  {
    slot: "0.02",
    oldVelocity: 0.43794,
    factor: 2,
    AVERAGE: 1,
    BAD: 1.625,
    SEVERE: 2.8887625,
  },
  {
    slot: "0.025",
    oldVelocity: 0.47162,
    factor: 2.1,
    AVERAGE: 1,
    BAD: 1.6,
    SEVERE: 2.7744,
  },
  {
    slot: "0.03",
    oldVelocity: 0.48996,
    factor: 2.1,
    AVERAGE: 1,
    BAD: 1.5,
    SEVERE: 2.601,
  },
  {
    slot: "0.035",
    oldVelocity: 0.50531,
    factor: 2.1,
    AVERAGE: 1,
    BAD: 1.56,
    SEVERE: 2.655588,
  },
  {
    slot: "0.05",
    oldVelocity: 0.56146,
    factor: 2.4,
    AVERAGE: 1,
    BAD: 1.5,
    SEVERE: 2.4375,
  },
  {
    slot: "0.075",
    oldVelocity: 0.67375,
    factor: 3,
    AVERAGE: 1,
    BAD: 1.4285,
    SEVERE: 2.14275,
  },
  {
    slot: "0.125",
    oldVelocity: 0.98754,
    factor: 2,
    AVERAGE: 1,
    BAD: 1.1,
    SEVERE: 1.298,
  },
];

export const max_vel_pg = {
  slot: "0.075",
  oldVelocity: 0.67375,
  factor: 3,
  AVERAGE: 1,
  BAD: 1.4285,
  SEVERE: 2.14275,
};

export const additional_info_options = [
  "1ST PULLING REPORT",
  "2ND PULLING REPORT",
  "3RD PULLING REPORT",
  "4TH PULLING REPORT",
  "5TH PULLING REPORT",
  "6TH PULLING REPORT",
  "7TH PULLING REPORT",
  "8TH PULLING REPORT",
  "9TH PULLING REPORT",
  "10TH PULLING REPORT",
];

export const pulling_initial_state = {
  id: 0,
  customName: "",
  pullingName: "1ST PULLING REPORT",
  well: {
    id: 0,
    name: "",
    contact: "",
    phoneNumber: "",
    client: {
      id: 0,
      name: "",
    },
  },
  installationDate: "",
  pullingDate: "",
  runtime: 0,
  details: [],
  recomendations: [],
};

export const pulling_details_line: PullingDesignDetailsProps = {
  description: new_tally_row,
  toolName: "",
  quantity: 1,
  pictures: [{ size: 1, image: "", rotation: "0", scale: 0 }],
  comment: [""],
};
