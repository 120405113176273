import React, { useContext } from "react";
import { ClientProps, WellProps } from "../../interfaces/interfaces";
import DataContext from "../../context/DataContext";
import TextField from "@mui/material/TextField";
import { Autocomplete, Grid } from "@mui/material";
import styles from "../components.module.sass";
import DesignerContext from "../../context/DesignerContext";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { additional_info_options } from "../../utils/data";

export function PullingForm() {
  const { clientOptions, wellOptions, fetchDataWells } =
    useContext(DataContext);
  const { pullingInfo, onEditPullingInfo } = useContext(DesignerContext);

  const defaultProps = {
    options: clientOptions,
    getOptionLabel: (option: ClientProps) => option.name,
  };

  const defaultPropsWell = {
    options: wellOptions,
    getOptionLabel: (option: WellProps) => option.name,
  };

  const handleChange = (name: string, value: any) => {
    onEditPullingInfo((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const defaultPropsAdditionalInfo = {
    options: additional_info_options,
    getOptionLabel: (option: string) => option,
  };

  return (
    <div className={styles.pullingForm}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            id="custom-design-name"
            label="Custom Design Name"
            variant="outlined"
            value={pullingInfo.customName}
            style={{ width: "100%" }}
            onChange={(event) => handleChange("customName", event.target.value)}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Autocomplete
            renderOption={(props, option) => {
              return (
                <li {...props} key={option}>
                  {option}
                </li>
              );
            }}
            value={pullingInfo.pullingName}
            isOptionEqualToValue={(option, value) => {
              return true;
            }}
            onChange={(_, newValue) =>
              newValue && handleChange("pullingName", newValue)
            }
            {...defaultPropsAdditionalInfo}
            sx={{ width: "100%" }}
            disablePortal
            className={styles.formFieldModalCustom}
            renderInput={(params) => (
              <TextField {...params} label="Additional Info" />
            )}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Autocomplete
            id="client-selector"
            value={pullingInfo.well.client}
            isOptionEqualToValue={(option, value) => {
              return true;
            }}
            onChange={(_, newValue) => {
              if (newValue) {
                onEditPullingInfo((prev) => {
                  return {
                    ...prev,
                    well: {
                      ...prev.well,
                      client: newValue,
                    },
                  };
                });
                newValue && fetchDataWells(newValue?.id);
              }
            }}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.id}>
                  {option.name}
                </li>
              );
            }}
            {...defaultProps}
            disablePortal
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField {...params} label="Client name" />
            )}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Autocomplete
            id="well-selector"
            value={pullingInfo.well}
            isOptionEqualToValue={(option, value) => {
              return true;
            }}
            onChange={(_, newValue) => {
              if (newValue) {
                onEditPullingInfo((prev) => {
                  return {
                    ...prev,
                    well: {
                      client: prev.well.client,
                      ...newValue,
                    },
                  };
                });
              }
            }}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.id}>
                  {option.name}
                </li>
              );
            }}
            {...defaultPropsWell}
            disablePortal
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField {...params} label="Well name" />
            )}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <DatePicker
            label="Installation Date"
            value={
              pullingInfo.installationDate
                ? dayjs(pullingInfo.installationDate)
                : dayjs()
            }
            onChange={(newValue: any) =>
              newValue && handleChange("installationDate", newValue)
            }
            className={styles.datepicker}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <DatePicker
            label="Pulling Date"
            value={
              pullingInfo.pullingDate ? dayjs(pullingInfo.pullingDate) : dayjs()
            }
            onChange={(newValue: any) =>
              newValue && handleChange("pullingDate", newValue)
            }
            className={styles.datepicker}
          />
        </Grid>
      </Grid>
    </div>
  );
}
